<template>
  <form @submit.prevent="handleSubmit" ref="theForm">
    <slot name="header"></slot>

    <b-row>
      <b-col>
        <b-form-group>
          <label><small>Business Name</small></label>
          <b-form-select
            v-model="businessCopy.id"
            :options="businesses"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <label><small>Gross Receipt</small></label>
          <b-form-input
            type="number"
            required
            v-model.number="businessCopy.grossReceipt"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <slot name="footer"></slot>
    <app-toast ref="toast"></app-toast>
  </form>
</template>

<script>
  import BusinessService from '@/services/BusinessService'

  export default {
    name: 'BusinessFormGrossReceipt',

    props: {
      business: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        businessCopy: { ...this.business },

        businesses: [{ value: null, text: 'Select a business' }]
      }
    },

    watch: {
      business: {
        deep: true,
        handler: 'copyBusiness'
      }
    },

    methods: {
      copyBusiness (newBusiness) {
        this.businessCopy = newBusiness
      },

      async loadBusinesses () {
        console.log('load business ...')

        try {
          // Find all business of the user
          const { id } = this.$store.state.user
          const allBusinesses = await BusinessService.findByUserId(id)

          // Append business name to businessOptions
          const businessOptions = allBusinesses.map(biz => {
            return { value: biz.id, text: biz.name }
          })

          this.businesses = this.businesses.concat(businessOptions)
          console.log('businesses loaded...')
        } catch (error) {
          this.$refs.toast.error(error.response.data.message)
        }
      },

      requestSubmit () {
        this.$el.requestSubmit()
      },

      submit () {
        this.$el.submit()
      },

      checkValidity () {
        return this.$el.checkValidity()
      },

      reportValidity () {
        return this.$el.reportValidity()
      },

      handleSubmit () {
        this.$emit('submitting', this.businessCopy)
      }
    },

    mounted () {
      this.loadBusinesses()
    }
  }
</script>

<style>
</style>
