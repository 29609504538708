<template>
  <b-container>
    <h4 class="page-title">Application for Business Renewal</h4>

    <b-row>
      <b-col>
        <b-card class="p-4">
          <business-form-gross-receipt
            ref="receiptForm"
            :business="selectedBusiness"
            @submitting="savePermit"
            class="mb-4"
          >
            <template v-slot:header>
              <h4 class="mb-4">Business Information</h4>
            </template>
          </business-form-gross-receipt>

          <required-docs-upload
            ref="uploadForm"
            :docs="documents"
            :saved="savedDocs"
            @submitting="uploadDocuments"
          >
            <template v-slot:header>
              <h4 class="mb-4">Required Document</h4>
            </template>
          </required-docs-upload>

          <hr />

          <b-button @click="handleSubmit" variant="primary" block size="lg"
            >Submit</b-button
          >
        </b-card>
      </b-col>
    </b-row>

    <app-spinner ref="spinner"></app-spinner>
    <app-toast ref="toast"></app-toast>
  </b-container>
</template>

<script>
  import PermitService from '@/services/PermitService'
  import BusinessFormGrossReceipt from '@/components/BusinessFormGrossReceipt.vue'
  import RequiredDocsUpload from '@/components/RequiredDocsUpload.vue'

  export default {
    name: 'BusinessRenew',

    components: {
      BusinessFormGrossReceipt,
      RequiredDocsUpload
    },

    data () {
      return {
        permitId: null,
        permitData: null,
        uploadData: null,

        selectedBusiness: {
          id: null,
          grossReceipt: ''
        },

        documents: {
          dtisec: null,
          lease: null,
          clearance: null,
          taxcert: null
        },

        savedDocs: []
      }
    },

    methods: {
      savePermit (data) {
        // Prepare permitData
        const expiredDate = new Date(new Date().getFullYear(), 11, 31)

        console.log('assign permitData')

        this.permitData = {
          businessId: data.id,
          grossReceipt: data.grossReceipt,
          issuedAt: new Date().toISOString().substr(0, 10),
          expiredAt: expiredDate.toISOString().substr(0, 10),
          type: 'Renew'
        }
      },

      uploadDocuments (docs) {
        // Prepare uploadData
        this.uploadData = new FormData()

        for (const prop in docs) {
          this.uploadData.append(prop, docs[prop])
        }
      },

      async handleSubmit () {
        this.$refs.receiptForm.requestSubmit()
        this.$refs.uploadForm.requestSubmit()

        this.$refs.spinner.show()

        try {
          const newPermit = await PermitService.save(this.permitData)

          this.savedDocs = await PermitService.saveDocuments(
            newPermit.id,
            this.uploadData
          )

          this.$refs.spinner.hide()
          this.$refs.toast.success(
            'Renewal appllication data submitted successfully.'
          )
          this.$router.go(0)
        } catch (error) {
          this.$refs.spinner.hide()
          this.$refs.toast.error(error.response.data.message)
        }
      }
    }
  }
</script>

<style>
</style>
